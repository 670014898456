.App {
  text-align: center;
  color: white;
}

.App-header {
  background-color: #121826; 
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}


.animated_background{
  background-image: url(https://media.giphy.com/media/xTiQyMdjaA5YzqTkI0/giphy.gif);
  background-size: cover;
  display: inline-block;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
}

#shift_R {
  animation: shiftR 2.5s ease-in-out 1s 1 forwards;
}
@keyframes shiftR {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(95%);
  }
}

#shift_J {
  animation: shiftJ 2.5s ease-in-out 1s 1 forwards;
}
@keyframes shiftJ {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(450%);
  }
}

#white_logo {
  animation: mymove 1s linear 1s 1 forwards;
}

@keyframes mymove {
  to {
    opacity: 0;
    display: none;
  }
}


/* NEW STUFF BELOW */

  #wrapper{
    display: flex;
    flex-direction: row;
  }
  
  #left {
    background-color: #121826;
    color: white;
  }
  
  #right {
    background-color: #121826;
    color: white;
  }

  .content {
    min-height: 100vh;
    min-width: 50vw;
  }
  
  
  @media screen and (max-width: 999px) {
       #wrapper { 
       flex-direction: column;
      }
      .content{
        min-height: 75vh;
      }

    h2{
      border-top: 2px solid rgb(70, 70, 70);
      padding-top: 10%;
    }

    #pic{
      height: 40%;
      width: 90%;
    }

    input[type=name]{
      width: 100%;
      padding: 12px 20px;
      display: flex;
      border: 1px solid #095e7c;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: #212936;
      color: white;
    }
    
    #message{
      width: 100%;
      padding: 5px;
      display: flex;
      border: 1px solid #095e7c;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: #212936;
      color: white;
    }

    #label_message{
      display: flex;
      margin: 0% 0%;
      margin-top: 7%;
    }
    
    label{
      display: flex;
      margin: 0% 0%;
      margin-top: 7%;
    }
  }

  @media screen and (min-width: 1000px) {
    .divider{
      position:absolute;
      left:50%;
      top:10%;
      bottom:10%;
      border-left:2px solid rgb(70, 70, 70);
    }

    #pic{
      margin-top: 5%;
      height: 46.5vh;
    }

    input[type=name]{
      width: 70%;
      padding: 12px 20px;
      margin: 2% 17%;
      display: flex;
      border: 1px solid #095e7c;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: #212936;
      color: white;
    }
    
    #message{
      width: 70%;
      padding: 5px;
      margin: 2% 17%;
      display: flex;
      border: 1px solid #095e7c;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: #212936;
      color: white;
    }

    #label_message{
      display: flex;
      margin: 0% 17%;
      margin-top: 5%;
    }
    
    label{
      display: flex;
      margin: 0% 17%;
      margin-top: 5%;
    }
}

#roles{
  padding-top: 7%;
  font-size: 150%;
}

#socials{
  display: flex;
  padding-top: 7%;
  padding-right: 10%;
  padding-left:  10%;
  justify-content: space-evenly;
}

input[type=submit] {
  background-color: #3684C2;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  opacity: 70%;
}

#divForm {
  margin-top: 5%;
  border-radius: 5px;
  background-color: #121826;
  padding: 10%;
}

#social_hover:hover{
  opacity: 70%;
}

